$(document).foundation();

$(document).ready(function() {

	function init_mobile_header(){
		if (Modernizr.mq('(max-width: 39.9375em)')) {
			$('#main_header').on( 'click', function(e){
				if( $('body').hasClass('scrolled') ){
					e.preventDefault();
					$('body').removeClass('scrolled');
				}
			});
		}
	}

	init_mobile_header();
	$(window).on('scroll', function(){
		init_mobile_header();
	});

	if($('body').hasClass('home')){

		var mySwiper = new Swiper('.swiper-container',{
		  "loop": true,
		  "keyboard": {
		    "enabled": true
		  },
		  "autoplay": {
		    "delay": 2000,
		    "disableOnInteraction": false
		  },
		  "effect": "fade",
		  "centeredSlides": true,
		  "pagination": {
		    "el": ".swiper-pagination",
		    "clickable": true,
		    "renderBullet": function (index, className) {
			    return '<button class="' + className + '" data-slide="' + (index + 1) + '"></button>';
		    }
		  }
		});

		$(window).scroll(function(){
			if ($(this).scrollTop() > 50) {
				$('#logo').addClass("resize-logo");
			} else {
				$('#logo').removeClass("resize-logo");
			}
		});
	}

	if($('body').hasClass('page-template-hotellerie')){
		var mySwiper = new Swiper('.swiper-container',{
		  "loop": true,
		  "keyboard": {
		    "enabled": true
		  },
		  "autoplay": {
		    "delay": 2000,
		    "disableOnInteraction": false
		  },
		  "effect": "fade",
		  "centeredSlides": true,
		  "pagination": {
		    "el": ".swiper-pagination",
		    "clickable": true,
		    "renderBullet": function (index, className) {
			    return '<button class="' + className + '" data-slide="' + (index + 1) + '"></button>';
		    }
		  }
		});
	}

	$(window).scroll(function(){
		if ($(this).scrollTop() > 20) {
			$('body').addClass("scrolled");
		} else {
			$('body').removeClass("scrolled");
		}
	});

	$('.scroll-top-anchor').on( 'click', function(e){
		e.preventDefault();
		$('html,body').animate({scrollTop:0}, 500);
	});

	if($('body').hasClass('page-template-savoir-faire') || $('body').hasClass('page-template-magasin')){

		var players = []

		$('.embed-container').each(function() {
		  players.push({
			  	elm: $(this).find("iframe"),
		      player: new Vimeo.Player($(this).find("iframe").get(0)),
		      top: $(this).position().top,
		      status: "paused"
		  })
		});

		$(window).on('scroll', function() {
			var scrollPos = $(window).scrollTop();
				for(var i=0; i<players.length;i++) {
					var elementFromTop = players[i].top - scrollPos;

			    var top_of_element = players[i].top;
			    var bottom_of_element = players[i].top + players[i].elm.outerHeight();
			    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
			    var top_of_screen = $(window).scrollTop();

			    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
			        // the element is visible, do something
			        var status = "play";
			    } else {
			        // the element is not visible, do something else
			        var status = "pause";
			    }

					if(players[i].status != status) {
						players[i].status = status;
						players[i].player[status]();
						console.log(i, status);
		      }
		    }
		});
	}
});